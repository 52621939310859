import * as React from "react"
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <title>2023 IRONMAN 70.3 World Championship Race Report</title>
        <meta
          name="msapplication-config"
          content="/assets/xml/browserconfig.xml"
        />
        <meta name="theme-color" content="#a6192d" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <meta itemprop="name" content="IRONMAN 70.3 World Championship Race Report" />
        <meta
          property="og:title"
          content="IRONMAN 70.3 World Championship Race Report"
        />
        <meta
          itemprop="image"
          content="https://colinlord.com/images/703-world-champ-race-report/meta.jpg"
        />
        <meta
          property="og:image"
          content="https://colinlord.com/images/703-world-champ-race-report/meta.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />

        <link
          href="https://fonts.googleapis.com/css?family=Quattrocento+Sans:400,700"
          rel="stylesheet"
        />

        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/css/bootstrap.min.css"
          integrity="sha384-F3w7mX95PdgyTmZZMECAngseQB83DfGTowi0iMjiWaeVhAn4FJkqJByhZMI3AhiU"
          crossorigin="anonymous"
        />

        <link href="/race-report.css" rel="stylesheet" />

        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/js/bootstrap.min.js"
          integrity="sha384-skAcpIdS7UcVUC05LJ9Dxay8AXcDYfBJqt1CJ85S/CFujBsIzCIv+l9liuYLaMQ/"
          crossorigin="anonymous"
        ></script>

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      </Helmet>

      <div className="container-fluid" style={{ overflow: "hidden" }}>
        <div className="row" style={{ margin: "auto -30px" }}>
          <div className="col-12">
            <img
              className="img-fluid"
              src="/images/703-world-champ-race-report/header.jpg"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="px-4 py-4 text-center border-bottom">
          <h1 className="display-4 fw-bold">
            IRONMAN 70.3 World Championship Race Report
          </h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-0">August 27, 2023</p>
            <p className="lead mb-0">Lahti, Finland</p>
          </div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Background</h1>
          <p>
            There’s no sense in burying the lede. What has happened over the past few days has been an unbelievable and life-changing experience amid a challenging year.
          </p>

          <p>
            I’ve already discussed it in Team Saunders episodes, <a href="https://www.instagram.com/p/CsYc0JruyvR/" target="_blank">an Instagram post</a>, and my <a href="https://colinlord.com/race-reports/2023/ironman-703-chattanooga/">Chattanooga race report</a>. There’s no reason to rehash that list of things that have happened…other than briefly saying this: At least once every single day, something will trigger the memory (and terror) of me having to give Katy CPR last November. Thankfully, she now has a clean bill of health! But even with that, my mental state continues to be a work in progress. I’m still seeing a therapist, and the reality has sunk in that this just may be something I can never completely put behind me.
          </p>

          <p>
            If there’s a positive, that experience has given me a deep sense of gratitude for things that I previously may have taken for granted. Every day with Katy is truly a gift. That mindset extends far beyond our marriage to all parts of my life. When it comes to triathlon, “How lucky am I?” is the most repeated question I’ve asked myself since May when I snagged a Worlds slot.
          </p>

          <p>
            Just 11 months ago, I was dragging around the house trying to recover from two bouts with COVID, six weeks apart. Now I’m writing a race report about the Ironman 70.3 World Championship.
          </p>

          <p>
            How lucky am I?
          </p>
        </div>
        <div className="px-4 py-4 border-bottom">
          <h1>Race Week</h1>
          <p>
            Before now, the extent of my international travel experience was trips to the Caribbean during my 20s and a trip to Toronto back in high school. I’ve never been on a flight longer than a few hours. Now, I was looking at a trip from Nashville to Lahti, which literally included planes, trains, and automobiles.
          </p>
          
          <p>Considering the complexity of the trip's logistics, getting out there couldn’t have gone much more smoothly. We left Nashville on Sunday morning. It took about 23 hours from leaving home to walking into our Airbnb on Monday morning. The Saunders arrived later in the evening. Trying to stay awake for their arrival helped me adjust my sleep schedule to Finland time.</p>
          
          <p>When I woke up on Tuesday, I felt pretty good. The “How lucky am I?” goosebump moments were fast and frequent from here on out through the weekend.</p>
          
          <p>I got to see my name on the wall of athletes who qualified.</p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/names.jpg" alt="" />
            </figure>
          </div>

          <p>We got to swim in a pool directly under Lahti’s ski jumps. I’m used to battling the Silver Sneaker crowd for a lane at the Williamson County Rec Center, and now I’m swimming HERE?</p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/ski-jump-pool.jpg" alt="" />
            </figure>
          </div>
          
          <p>On Wednesday, we did an open water swim in Lake Vesijärvi. The water was cold but nowhere near as bad as I had feared. This lake couldn’t be more picturesque. It was an incredible place to be able to swim that day and to race that weekend.</p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/lake-swim.jpg" alt="" />
            </figure>
          </div>
          
          <p>
            Thursday reunited us with our bikes that we hadn’t seen since shipping them off weeks ago back in the United States. I hadn’t ridden my bike in a month, but it felt like reuniting with an old friend. Everything just clicked, even though we hadn’t seen each other in a while. I truly felt race ready now.
          </p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/bike-shakeout.jpg" alt="" />
            </figure>
          </div>
          
          <p>
            That evening brought us to the Parade of Nations at Lahden Stadion. What an incredible moment to see how many different countries were represented here! People had literally come from all across the planet.
          </p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/parade-nations.jpg" alt="" />
            </figure>
          </div>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/parade-nations2.jpg" alt="" />
            </figure>
          </div>
          
          <p>
            On Friday morning, we did a short run in our neighborhood, and then it was time to get ready to race!
          </p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/shakeout-run.jpg" alt="" />
            </figure>
          </div>
          
          <p>
            I always get nervous when packing my gear bags. Did I forget anything? The next time I open these will be during the race!
          </p>
          
          <p>
            (Pro tip: Take a picture of everything that you put into each bag. When you wake up at 3 a.m. in a cold sweat that you forgot something, you can check your camera roll, verify that it’s there, and go right back to sleep.)
          </p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/gear-bags.jpg" alt="" />
            </figure>
          </div>
          
          <p>Saturday brought race day for the Saunders and the rest of the women’s field. I couldn’t wait to cheer them on, but I feared this day could be hard on me mentally. I’d never watched a race the day before a race! How would this affect those pre-race jitters I always have to fight through?</p>
          
          <p>Things were great! Being a spectator kept my mind at ease instead of endlessly thinking about tomorrow. Plus, I was so freaking happy for both of them. They’ve both had battles of their own to get to Finland. To see them get to race in the World Championship? I couldn’t be happier for them.</p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/hug.jpg" alt="" />
            </figure>
          </div>
          
          <p>After we returned to the Airbnb that evening, I had a long conversation with them to get all the course recon I could. Remarkably, I fell asleep without much issue! I felt ready for my first ever 70.3 World Championship.</p>
          
          
        </div>
        <div className="px-4 py-4 border-bottom">
          <h1>Race Morning</h1>
          <p>
            I remember being struck by how calm I felt. I’d spent the entire summer training for this day; it was finally here, and my usual pre-race nerves were nowhere to be found.
          </p>
          
          <p>
            I felt calm. I felt excited. I felt confident! Katy is always snapping photos of me before Ironman events, and I’ve never seen this expression on my face in any of those pre-race photos before.
          </p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/swim-stretch.jpg" alt="" />
            </figure>
          </div>
          
          <p>It was a treat when they had everyone stand for the national anthem. For the first time in my race career, it wasn’t the Star Spangled Banner that I heard. It was just one more reminder that today would be such a special day.</p>
          
          <p>The pro athletes were set to begin at 7:30 a.m. My age group would follow at 8:06 a.m. Many of the best triathletes in the world were all standing here in the starting corral.</p>
          
          <p>And so am I!</p>

        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Swim</h1>
          
          <p>
            <strong>Time:</strong> 39:06
            <br />
            <strong>Distance:</strong> 1.2 miles
            <br />
            <strong>Pace:</strong> 1:51/100 yd
            <br />
            <strong>AG:</strong> 426/583
            <br />
            <strong>Overall:</strong> 2674/3935
            <br />
          </p>
          <p>
            <strong>Weather:</strong> Party Cloudy
            <br />
            <strong>Water Temperature:</strong> 68°F
            <br />
            <strong>Current:</strong> None
            <br />
            <strong>Wetsuit:</strong> Yes
          </p>
          
          <p>I love the final moments before the race when we are all packed together shoulder to shoulder in the starting corral, waiting to jump in the water.</p>
          
          <p>Arriving at the starting line of any triathlon, much less an Ironman event, is not easy and never an accident. Standing there represents early mornings in the cold, afternoons in the blazing heat, and everything in between. Standing there means all the race week logistics are finally complete. We all made it. There’s finally nothing left to do. Except race!</p>
          
          <p>The day before, the women’s race got delayed by fog. But today, the conditions were perfect across Lake Vesijärvi.</p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/mens-swim.jpg" alt="" />
            </figure>
          </div>
          
          <p>As I inched forward, I tried not to think about why the carpet was getting progressively squishier (if you don’t know, you don’t want to ask! 😂), and eventually, I was standing at the edge. LET’S! GO!</p>
          
          <p>I remember thinking about how my timing chip had just activated, and that people back home were actually up in the middle of the night tracking me. Not only that, Katy, Tiffany, and Caitlin were here in person, and their phones all just went off as well. I get so much out of this sport, even if nobody is watching. But to know that people WERE watching? It means the world to me.</p>
          
          <p>This course was fantastic. It never felt crowded. Frank told me to swim at about 90% of threshold. In the past, I’ve made the mistake of trying to keep to a specific pace, but that means glancing at my watch, which will slow me down.</p>
          
          <p>So while I didn’t know my specific pace, the buoys seemed to pass quickly! I made each of the three turns, and before I knew it, I was swimming straight at the shoreline and thinking through the logistics for transition.</p>
          
          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Transition 1: Swim to Bike</h1>
          <p>
            I kept hearing my name, which gave me such a boost. Tiffany was at the top of the stairs as I exited the water. I ran by Caitlin near the gear bags. Katy was by the bike exit.
          </p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/t1.jpg" alt="" />
            </figure>
          </div>
          
          <p>Time to bike!</p>
          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Bike</h1>
          
          <p>
            <strong>Time:</strong> 2:41:21
            <br />
            <strong>Distance:</strong> 56 miles
            <br />
            <strong>Pace:</strong> 20.9 mph
            <br />
            <strong>AG:</strong> 434/583
            <br />
            <strong>Overall:</strong> 2682/3935
            <br />
          </p>
          <p>
            <strong>Weather:</strong> Heavy rain
            <br />
            <strong>Road Conditions:</strong> Scary and slick
            <br />
            <strong>Terrain:</strong> Rollers throughout, with the steepest climb at mile 55. 2572 feet of elevation gain.
          </p>
          
          <p>It was hard to go anywhere during race week without seeing or hearing about “Sisu” — the Finnish concept of unwavering determination, resilience, and courage in the face of adversity. The word is even on our finisher medals.</p>
          
          <p>We all were about to have to find our Sisu.</p>
          
          <p>As I cycled out of Lahti for 56 miles in the gorgeous Finnish countryside, I noticed clouds starting to roll in from the west.</p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/rain.jpg" alt="" />
            </figure>
          </div>
          
          <p>That gave way to drizzle, which quickly turned into a torrential downpour. I had spent all summer training for and thinking about this day, but racing in heavy rain at 50 degrees had never entered my mind.</p>
          
          <p>The rain was coming down hard enough for water to be ponding in my shoes. With each rotation of the pedals, I could feel water sloshing around.</p>
          
          <p>I noticed a few athletes at the first aid station who had stopped and were in those aluminum warming blankets that you typically only see during winter running events. I never felt like I was close to a point where I needed to stop or withdraw from the race, but I did have concerns nonetheless.</p>
          
          <p>My toes were half numb, and getting a feel for the pedals and bike wasn't quite as easy. Same with my hands. I had to visibly look at my hands when I shifted gears because I couldn’t go by feel. Opening gel packets to keep taking in calories wasn’t easy either. Above all else, the course was very slick! There were a lot of curves, and they’d all have to be taken much more carefully.</p>
          
          <p>About halfway through, I got reprimanded by a race official for riding too close to the middle of the road. I wasn’t passing, drafting, or blocking, so I appreciate his discretion in not getting a penalty. I was just trying to stay away from the shoulder where the water was flowing!</p>
          
          <p>It was dumping buckets on me in my first World Championship race, but this course was gorgeous, even in the lousy weather. Sure, the sunflowers looked much more picturesque yesterday when the Saunders biked by them. But without rain, there are no sunflowers!</p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/sunflowers.jpg" alt="" />
            </figure>
          </div>

          <p>It made for an odd paradox. These were the worst conditions I’d ever raced in. This also was the strongest I’d ever felt in a race. The miles were ticking by quickly. I was catching and passing many of the athletes from the wave who had started before me. I was uncomfortable and shivering, but I felt I had a handle on things. I had my Sisu!</p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/rain2.jpg" alt="" />
            </figure>
          </div>
          
          <p>As I approached the end of the course and returned to Lahti, I felt great physically and mentally. Once I felt like I had a feel for how the bike handled in a downpour, my biggest worry became if I had some kind of mechanical issue like a flat. Those are challenging enough during a race, and that’s without nearly numb hands. However, as the final miles ticked off, I remember thinking I was close enough now that I could just push the damn bike the rest of the way if I had to.</p>
          
          <p>I didn’t know it at the time, but even in this weather, this was my fastest-ever 70.3 bike split.</p>

          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Transition 2: Bike to Run</h1>

          <p>
            Running a half marathon after a 1.2-mile swim and a 56-mile bike ride is not for the faint of heart. But, at least from a logistical perspective, things get more straightforward once you make it to the run. I’m always excited when I get to this point in the race. There’s still work to do, but it’s down to just my two feet now.
          </p>
          
          <p>
          I came charging out of T2, knowing I was just 13 miles from a World Championship finish line. It’s a dangerous game taunting Mother Nature, but I felt like no amount of rain would stop me now.
          </p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/t2.jpg" alt="" />
            </figure>
          </div>
          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Run</h1>
          
          <p>
            <strong>Time:</strong> 1:45:16
            <br />
            <strong>Distance:</strong> 13.1 miles
            <br />
            <strong>Pace:</strong> 8:04 / mile
            <br />
            <strong>AG:</strong> 432/583
            <br />
            <strong>Overall:</strong> 2590/3935
            <br />
          </p>
          <p>
            <strong>Weather:</strong> On and off rain
            <br />
            <strong>Road Conditions:</strong> Mostly asphalt with a few gravel sections
            <br />
            <strong>Terrain:</strong> 650 feet of elevation gain, primarily in one big hill 
          </p>

          <p>As I splashed out to begin the run, there were Katy, Tiffany, and Caitlin - now in rain jackets - but still out there offering cheers and encouragement. I know I keep saying it, but how lucky am I?</p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/track-run.jpg" alt="" />
            </figure>
          </div>
          
          <p>The run course was two loops, each a little over six miles, through Lahti and along Lake Vesijärvi. As I worked my way up a hill, somebody from New Zealand ran over to me and said, “Glad to see you shaved those legs again, mate.” He had recognized me from the Team Saunders videos on YouTube. Incredible.</p>
          
          <p>Katy generally tries to pop up alongside the run course as often as possible during Ironman events to cheer me on. It’s really sweet that she does this. She can find me often since the run courses generally have loops and stay in one area. When I look back at my runs in Strava, I can spot the locations of Katy sightings because it always results in a faster pace for a little while.</p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/lake-run.jpg" alt="" />
            </figure>
          </div>
          
          <p>The first loop of the course went well, with one exception. It was hard to pace myself. I’ve learned the hard way not to trust my watch when I’m running curvy roads under trees. The GPS is generally close, but it’s not accurate enough for pacing. Instead, I look at the time on my watch and compare it to the mile markers. But the course wasn’t marked in miles; it was marked in kilometers!</p>
          
          <p>Enter the Saunders to save the day. Tiffany had been doing the math using my chip times in the Ironman app. As I started the second loop, Caitlin came charging up beside me. She shouted not just encouragement but also split times and the pace I needed to run to keep my streak of PRs alive.</p>
          
          <p>I couldn’t ask for better teammates.</p>
          
          <p>Remarkably, in every race in 2023, I have set a new PR for that respective distance. It’s a streak that logically should have ended many months ago, but it was still intact here at the end of August. If I could run the final 6.5 miles in 52 minutes or faster, I’d beat my Chattanooga time of 5:24.</p>
          
          <p>If I’m being perfectly honest, I had a moment where I wanted to dismiss what Caitlin told me. Was 52 minutes possible? I think so? But gulp, those are roughly 8-minute miles at the end of a long, wet day. Would the race be any less special if I just ran the final loop at an easier pace, took in the magic of where I was, and jogged down the carpet to fully enjoy the experience of crossing the finish line?</p>
          
          <p><em>Fuck that. I’m going for it.</em></p>
          
          <p>Katy told me that as she saw me throughout the second loop, my face had a more pained expression each time I passed her. YEP! But this right here. This is the magic of triathlon. You find things in yourself you didn’t know you had.</p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/tired.jpg" alt="" />
            </figure>
          </div>
          
          <p>I wish I had a more dramatic story to tell, but there was no uncertainty here. With about a mile to go, I had it. Thanks to a couple of sub-7:30 minute miles, unless I slipped and fell over on the slick asphalt, this incredible experience would be my best 70.3 ever. Caitlin had yelled that I had to do 52 minutes. I threw down 47 on the second loop.</p>
          
          <p>I hit the soggy carpet and made my way to the finish line.</p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/soggy-carpet.jpg" alt="" />
            </figure>
          </div>
          
          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>The Finnish Line</h1>
          
          <p>
            <strong>Overall Time:</strong> 5:19:49
            <br />
            <strong>AG:</strong> 432/583
            <br />
            <strong>Overall:</strong> 2590/3935
            <br />
          </p>

          <p>I suspected that I’d get teary-eyed crossing the finish line, but I was completely unprepared for the explosion of tears and emotions that just wouldn’t stop. Right after I finished, a volunteer rushed over, looking quite concerned, threw a blanket around me, and wanted to escort me to the EMS folks. I tried to tell him I was physically fine!</p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/towel.jpg" alt="" />
            </figure>
          </div>
          
          <p>I walked right by the post-race food, the spot for finisher photos, and even forgot to return my timing chip. It was impossible for me not to think about an alternate reality where things went differently in November. In that scenario, Katy isn’t here waiting for me just beyond the finisher tent.</p>
          
          <p>But she <em>was</em> here. 🥹 And I needed to see her <em>right now</em>.</p>
          
          <p>Life is fragile. For her to be here while a Worlds medal hangs around my neck? It was a very emotional moment for me, full of gratitude.</p>
          
          <p>I ate a little pizza, and then we walked over to where Tiffany and Caitlin were. They also had to deal with an emotional Colin.</p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/tears.jpg" alt="" />
            </figure>
          </div>
          
          <p>I was so focused on finding Katy that I literally didn’t know my overall time until they told me. Despite the bad weather, I’d beat my Chattanooga time by five minutes. In a season where my original goal was to finish a 70.3 in under six hours for the first time, I’d just finished Worlds in 5:19:49 with my wife and two of my best friends there to witness it.</p>
          
          <p>How lucky am I?</p>

          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4">
          <h1>Final Thoughts</h1>
          <p>The weather during the race seems like an appropriate metaphor for life over the past year. The day started sunny, terrible conditions moved in, and I had to adapt. However, I still saw things through to the finish.</p>
          
          <p>When I think about Worlds 2023 in the future, it’ll be impossible for me not to also reflect on the challenges of this past year. They are far too intertwined with each other.</p>
          
          <p>After my first appointment last winter, my therapist told me she would prescribe me two things. First, she put me on a PTSD medication. Then she told me to lean into triathlon because it surrounds me with positive and uplifting people. As hard as it can be, it’s helped keep me grounded on days I might have otherwise unraveled.</p>
          
          <p>How lucky am I to have this sport and everything that comes with it?</p>
          
          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-world-champ-race-report/finish.jpg" alt="" />
            </figure>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    </main>
  );
}

export default IndexPage
